import axios from 'axios';
import config from '@/config/env-constants';

function getProjectId() {
    if (config.currEnv === 'pallet-pooling') {
        return `${config.currEnv}`;
    } else {
        return `ayun-quantity-${config.currEnv}`;
    }
}

export default {
    baseUrl: `https://us-central1-${getProjectId()}.cloudfunctions.net`,

    getDashboard(currUserId, filterBy) {
        let url = `${this.baseUrl}/getDashboard`;
        return axios.post(url, {
            currUserId: currUserId,
            filterBy: JSON.stringify(filterBy)
        });
    }
}